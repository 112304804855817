import {Icon} from '@iconify/react'
import triangleAlert from '@iconify-icons/akar-icons/triangle-alert'

import CardValidStac from './CardValidStac'
import CardInvalidStac from './CardInvalidStac'
import {ValidStac, InvalidStac} from '../types'

type ResultsDisplayProps = {
  /** The results which come back from the validator API request */
  results: ValidStac | InvalidStac | null
  /** Indicates a client-side error */
  clientError: boolean
  /** Indicates a server-side error */
  serverError: boolean
  /** Determines whether to display a valid card or invalid card */
  validStac: boolean
}

/** Results display card for a STAC validation request */
export default function ValidationResultsDisplay(props: ResultsDisplayProps) {
  const {results, clientError, serverError, validStac} = props

  if (clientError || serverError) {
    return (
      <section className="results">
        <div className="results-display results-display-error">
          <div className="results-display-title">
            <div className="results-display-icon">
              <Icon icon={triangleAlert} className="error" style={{width: 32, height: 32}} />
            </div>
            <div>
              <h2>{clientError ? 'Client Error' : 'Server Error'}</h2>
            </div>
          </div>
          <div className="results-display-body">
            <p>We are unable to lint this STAC due to an error</p>
            <p>If this continues, raise an issue in our github </p>
          </div>
        </div>
      </section>
    )
  }

  if (!results) return <></>

  // tslint-disable-next-line
  return validStac ? <CardValidStac {...results} /> : <CardInvalidStac {...results} />
}
