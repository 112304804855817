import {useReducer, useState} from 'react'

import ButtonToggleMode from './ButtonToggleMode'
import ValidationResultsDisplay from './ValidationResultsDisplay'
import ButtonTriggerValidation from './ButtonTriggerValidation'

import {ValidationReducerAction as Action, ValidationReducerState as State} from '../types'

// URL Regexp
const validUriRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

type ValidatorProps = {
  /** Set to true when mobile device detected */
  isMobile: boolean
  /** Function which toggles visiblity */
  toggleValidatorMode: () => void
  /** Triggers visibility */
  visible: boolean
}

const initialState = {
  results: null,
  loading: false,
  clientError: false,
  serverError: false,
  validStac: false,
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'request':
      return {...state, loading: true}
    case 'valid-stac':
      return {
        results: action.results,
        loading: false,
        serverError: false,
        clientError: false,
        validStac: true,
      }
    case 'invalid-stac':
      return {
        results: action.results,
        loading: false,
        serverError: false,
        clientError: false,
        validStac: false,
      }
    case 'server-error':
      return {...state, serverError: true, loading: false}
    case 'client-error':
      return {...state, clientError: true, loading: false}
  }
}

/** Stac validator with single text input  */
export default function StacValidatorUrl(props: ValidatorProps) {
  const {isMobile, toggleValidatorMode, visible} = props
  const [state, dispatch] = useReducer(reducer, initialState)
  const [url, setUrl] = useState('')

  async function validate() {
    dispatch({type: 'request'})

    const response = await fetch(`//api.staclint.com/url/?stac_url=${url}`).then((response) => {
      if (response.status >= 500) {
        dispatch({type: 'server-error'})

        return null
      } else if (response.status >= 400) {
        dispatch({type: 'client-error'})

        return null
      } else {
        return response.json()
      }
    })

    if (response) {
      if (response.body.valid_stac) {
        dispatch({
          type: 'valid-stac',
          results: {
            version: response.body.version,
            schema: response.body.schema,
            assetType: response.body.asset_type,
          },
        })
      } else {
        dispatch({
          type: 'invalid-stac',
          results: {
            version: response.body.version,
            schema: response.body.schema,
            errorType: response.body.error_type,
            errorMessage: response.body.error_message,
          },
        })
      }
    }
  }

  const displayResults = state.results || state.clientError || state.serverError

  return (
    <section className={`validation-pane ${!visible && 'not-visible'}`}>
      {!isMobile && <ButtonToggleMode onClick={toggleValidatorMode} urlMode />}
      <div className="form">
        <div>
          <div className="spacer">
            <label>Provide an external URL to validate</label>
          </div>
          <div className="spacer">
            <input placeholder="url" value={url} onChange={(e) => setUrl(e.target.value)} />
          </div>

          <ButtonTriggerValidation
            onClick={validate}
            disabled={!validUriRegexp.test(url)}
            loading={state.loading}
          />
        </div>
      </div>
      {displayResults && (
        <ValidationResultsDisplay
          results={state.results}
          validStac={state.validStac}
          clientError={state.clientError}
          serverError={state.serverError}
        />
      )}
    </section>
  )
}
