import {Icon} from '@iconify/react'
import checkIcon from '@iconify-icons/akar-icons/check'

import {ValidStac} from '../types'

/** Displays information regarding a valid stac submitted to the validator */
export default function CardValidStac(props: ValidStac) {
  const {version, schema, assetType} = props

  return (
    <section className="results">
      <div className="results-display results-display-success">
        <div className="results-display-title">
          <div className="results-display-icon">
            <Icon icon={checkIcon} className="success" style={{width: 32, height: 32}} />
          </div>
          <div>
            <h2>Valid Stac</h2>
          </div>
        </div>
        <div className="results-display-body">
          <h3>Asset Type</h3>
          <p>{assetType}</p>
          <h3>Schema</h3>
          <ol>
            {schema.map((link) => (
              <li key={link}>
                <a href={link} target="_blank" rel="noreferrer">
                  {link}
                </a>
              </li>
            ))}
          </ol>
          <h3>Version</h3>
          <p>{version}</p>
        </div>
      </div>
    </section>
  )
}
