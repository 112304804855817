import {useReducer, useRef} from 'react'

import Editor from '@monaco-editor/react'
import ValidationResultsDisplay from './ValidationResultsDisplay'
import {ValidationReducerAction as Action, ValidationReducerState as State} from '../types'
import ButtonTriggerValidation from './ButtonTriggerValidation'

import ButtonToggleMode from './ButtonToggleMode'

type ValidatorProps = {
  /** Function that toggles which validator is visible */
  toggleValidatorMode: () => void
  /** Triggers a CSS class for visibility */
  visible: boolean
}

const initialState = {
  results: null,
  loading: false,
  clientError: false,
  serverError: false,
  validStac: false,
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'request':
      return {...state, loading: true}
    case 'valid-stac':
      return {
        results: action.results,
        loading: false,
        serverError: false,
        clientError: false,
        validStac: true,
      }
    case 'invalid-stac':
      return {
        results: action.results,
        loading: false,
        serverError: false,
        clientError: false,
        validStac: false,
      }
    case 'server-error':
      return {...state, serverError: true, loading: false}
    case 'client-error':
      return {...state, clientError: true, loading: false}
  }
}

/** Stac validator with code editor  */
function StacValidatorText(props: ValidatorProps) {
  const {toggleValidatorMode, visible} = props
  const [state, dispatch] = useReducer(reducer, initialState)

  const editorContent = useRef()

  async function validate() {
    dispatch({type: 'request'})

    let body
    try {
      body = editorContent.current()
    } catch (e) {
      console.error(e)
      return
    }

    if (body.length === 0) return

    const response = await fetch('//api.staclint.com/json', {
      method: 'POST',
      body,
    })
      .then((response) => {
        if (response.status >= 500) {
          dispatch({type: 'server-error'})

          return null
        } else if (response.status >= 400) {
          dispatch({type: 'client-error'})

          return null
        } else {
          return response.json()
        }
      })
      .catch(() => {
        dispatch({type: 'server-error'})
      })

    if (response) {
      if (response.body.valid_stac) {
        dispatch({
          type: 'valid-stac',
          results: {
            version: response.body.version,
            schema: response.body.schema,
            assetType: response.body.asset_type,
          },
        })
      } else {
        dispatch({
          type: 'invalid-stac',
          results: {
            version: response.body.version,
            schema: response.body.schema,
            errorType: response.body.error_type,
            errorMessage: response.body.error_message,
          },
        })
      }
    }
  }

  function handleEditorDidMount(_editorContent: any) {
    editorContent.current = _editorContent
  }

  const displayResults = state.results || state.clientError || state.serverError

  return (
    <div className={`validation-text-editor ${!visible && 'not-visible'}`}>
      <section>
        <Editor height="90vh" language="json" editorDidMount={handleEditorDidMount} theme="dark" />
      </section>

      <section className="validation-pane">
        <ButtonToggleMode onClick={toggleValidatorMode} textMode />

        <div className="form">
          <div>
            <div className="spacer">
              <label>Paste a STAC object to validate</label>
            </div>

            <ButtonTriggerValidation
              onClick={validate}
              disabled={editorContent?.current?.length < 1}
              loading={state.loading}
            />
          </div>
          {displayResults && (
            <ValidationResultsDisplay
              results={state.results}
              validStac={state.validStac}
              clientError={state.clientError}
              serverError={state.serverError}
            />
          )}
        </div>
      </section>
    </div>
  )
}

export default StacValidatorText
