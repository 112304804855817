type TriggerValidationButtonProps = {
  /** The action carried out on click of the button */
  onClick: () => void
  /** Triggers the disabled state of the button */
  disabled: boolean
  /** Triggers the loading state of the button */
  loading: boolean
}

/** Button used to display loading state */
export default function ButtonTriggerValidation(props: TriggerValidationButtonProps) {
  const {disabled, loading, onClick} = props

  return (
    <button onClick={onClick} disabled={disabled} className="indicator-validating">
      {loading ? (
        <>
          <span>Validating</span> <div className="validation-loading" />
        </>
      ) : (
        <span>Validate</span>
      )}
    </button>
  )
}
