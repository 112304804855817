import {Icon} from '@iconify/react'
import triangleAlert from '@iconify-icons/akar-icons/triangle-alert'

import {InvalidStac} from '../types'

/** Displays information regarding an invalid stac submitted to the validator */
export default function CardValidInvalidStac(props: InvalidStac) {
  const {version, schema, errorType, errorMessage} = props

  return (
    <section className="results">
      <div className="results-display results-display-error">
        <div className="results-display-title">
          <div className="results-display-icon">
            <Icon
              icon={triangleAlert}
              className="error error-name"
              style={{width: 32, height: 32}}
            />
          </div>
          <div>
            <h2>Invalid STAC: {errorType}</h2>
          </div>
        </div>
        <div className="results-display-body">
          <h3>Error Message</h3>
          <p>{errorMessage}</p>
          <h3>Schema</h3>
          <ol>
            {schema.map((link) => (
              <li key={link}>
                <a href={link} target="_blank" rel="noreferrer">
                  {link}
                </a>
              </li>
            ))}
          </ol>
          <h3>Version</h3>
          <p>{version}</p>
        </div>
      </div>
    </section>
  )
}
