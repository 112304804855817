type ToggleModeProps = {
  /** Function that toggles between textMode and urlMode */
  onClick: () => void
  /** True when the linter has the editor open */
  textMode?: boolean
  /** True when the linter has the editor closed and the single text input open */
  urlMode?: boolean
}

/** Button responsible for toggling between text and url modes for staclint */
export default function ButtonToggleMode(props: ToggleModeProps) {
  const {textMode = false, onClick, urlMode = false} = props
  if (!(textMode || urlMode)) {
    throw new Error('In ButtonToggleMode, one of "textMode" or "urlMode" must be set to true')
  }

  return (
    <button onClick={onClick} className="toggle-mode">
      {textMode && (
        <>
          <div className="label-element">
            <span>STAC URL</span>
          </div>
          <div className="label-element left-border">
            <span>◀</span>
          </div>
        </>
      )}
      {urlMode && (
        <>
          <div className="label-element">
            <span>STAC editor</span>
          </div>
          <div className="label-element left-border">
            <span>▶</span>
          </div>
        </>
      )}
    </button>
  )
}
