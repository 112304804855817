import {useEffect, useState} from 'react'

import StacValidatorText from './StacValidatorText'
import StacValidatorUrl from './StacValidatorUrl'

const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
const isAndroid = () => Boolean(userAgent.match(/Android/i))
const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
const isWindows = () => Boolean(userAgent.match(/IEMobile/i))

const detectMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows())

/** Contains state for toggling between text and url */
const ValidationSelector = () => {
  const toggleValidatorMode = () => setEditorTextMode(!editorTextMode)

  const isMobile = detectMobile()
  // Defaults to text mode on desktop, url mode in mobile
  const [editorTextMode, setEditorTextMode] = useState(!isMobile)

  useEffect(() => {
    if (isMobile) {
      setEditorTextMode(false)
    }
  }, [setEditorTextMode, isMobile])

  return (
    <>
      <StacValidatorText toggleValidatorMode={toggleValidatorMode} visible={editorTextMode} />

      <StacValidatorUrl
        toggleValidatorMode={toggleValidatorMode}
        visible={!editorTextMode}
        isMobile={isMobile}
      />
    </>
  )
}

export default ValidationSelector
