import Layout from './layouts/Layout'
import ValidationSelector from './components/ValidationSelector'
import {ToastContainer} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <Layout>
        <ValidationSelector />
      </Layout>
      <ToastContainer position="bottom-center" hideProgressBar closeOnClick />
    </>
  )
}

export default App
