import React from 'react'

import {InlineIcon} from '@iconify/react'
import mediumSquareFilled from '@iconify/icons-ant-design/medium-square-filled'
import githubFilled from '@iconify/icons-ant-design/github-filled'

import Logo from '../assets/logo.svg'

const specificationExplainer =
  'https://medium.com/radiant-earth-insights/announcing-the-spatiotemporal-asset-catalog-stac-specification-1db58820b9cf'
const specificationRepo = 'https://github.com/radiantearth/stac-spec'

type LayoutProps = {
  children: React.ReactNode
}

/**
 *
 * The raw HTML for both the header and the footer of staclint
 */
export default function Layout(props: LayoutProps) {
  const {children} = props

  return (
    <>
      <header>
        <div>
          <img src={Logo} width="150" alt="STACLint" />
        </div>
        <a
          href={specificationExplainer}
          target="_blank"
          rel="noreferrer noopener"
          className="link-specification"
        >
          <InlineIcon icon={mediumSquareFilled} />

          <span className="link-text-span">Overview</span>
        </a>
        <a
          href={specificationRepo}
          target="_blank"
          rel="noreferrer noopener"
          className="link-repository"
        >
          <InlineIcon icon={githubFilled} />

          <span className="link-text-span">Repo</span>
        </a>
      </header>
      <main>{children}</main>

      <footer>
        <a href="https://sparkgeo.com" target="_blank" rel="noopener noreferrer">
          Made by Sparkgeo
        </a>

        <div className="spacer">|</div>
        <a
          href="https://pypi.org/project/stac-validator/"
          target="_blank"
          rel="noopener noreferrer"
        >
          STAC Validator (Python)
        </a>

        <div className="spacer">|</div>
        <a href="https://github.com/sparkgeo/staclint" target="_blank" rel="noopener noreferrer">
          Staclint Repo
        </a>
      </footer>
    </>
  )
}
